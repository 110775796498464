import unsavedChanges from '@/utility/scripts/unsavedChanges'

export const preventUnsavedChangesDataLevel = {
  watch: {
    dirty(value) {
      if (value) {
        window.onbeforeunload = () => 'confirm'
      } else {
        window.onbeforeunload = null
      }
    },
  },

  methods: {
    async wannaLeave() {
      if (!this.dirty) return true
      return unsavedChanges(this)
    },
  },

  async beforeRouteUpdate(to, from, next) {
    if (!this.dirty) {
      window.onbeforeunload = null
      next()
      return
    }
    const stillLeave = await this.wannaLeave()
    if (stillLeave) {
      window.onbeforeunload = null
      next()
    }
  },

  async beforeRouteLeave(to, from, next) {
    if (!this.dirty) {
      window.onbeforeunload = null
      next()
      return
    }
    const stillLeave = await this.wannaLeave()
    if (stillLeave) {
      window.onbeforeunload = null
      next()
    }
  },
}

export const preventUnsavedChangesRouteLevel = {
  data: () => ({
    dirty: false,
  }),

  watch: {
    dirty(value) {
      if (value) {
        window.onbeforeunload = () => 'confirm'
      } else {
        window.onbeforeunload = null
      }
    },
  },

  mounted() {
    setTimeout(() => {
      if (
        this.$refs.rules
        && this.$refs.rules.flags
        && this.$refs.rules.flags.dirty !== undefined
      ) {
        this.$watch(
          () => this.$refs.rules.flags.dirty,
          val => {
            this.dirty = val
          },
        )
      }
    }, 500)
  },

  methods: {
    async wannaLeave() {
      if (!this.dirty) return true

      const stillWantToLeave = await unsavedChanges(this)
      if (stillWantToLeave) return true

      return false
    },
  },

  async beforeRouteUpdate(to, from, next) {
    const stillLeave = await this.wannaLeave()
    if (stillLeave) {
      window.onbeforeunload = null
      next()
    }
  },

  async beforeRouteLeave(to, from, next) {
    const stillLeave = await this.wannaLeave()
    if (stillLeave) {
      window.onbeforeunload = null
      next()
    }
  },
}

export const preventUnsavedChangesPopupLevel = {
  data: () => ({
    visible: false,
    modalIsVisible: false,
    dirty: false,
  }),

  watch: {
    dirty(value) {
      if (value) {
        window.onbeforeunload = () => 'confirm'
      } else {
        window.onbeforeunload = null
      }
    },
  },

  computed: {
    sidebarVisible: {
      get() {
        return this.visible
      },
      async set(value) {
        if (value) {
          this.$refs.sidebar.localShow = true
          return
        }

        if (this.dirty) {
          const stillWantToLeave = await unsavedChanges(this)
          if (!stillWantToLeave) return
        }

        this.$refs.sidebar.localShow = false
        this.visible = false
        this.reset()
      },
    },
    modalVisible: {
      get() {
        return this.modalIsVisible
      },
      async set(value) {
        if (value) {
          this.$refs.modal?.show()
          return
        }

        if (this.dirty) {
          const stillWantToLeave = await unsavedChanges(this)
          if (!stillWantToLeave) return
        }

        this.$refs.modal.hide()
        this.modalIsVisible = false
        this.reset()
      },
    },
  },

  mounted() {
    setTimeout(() => {
      if (this.$refs.sidebar) {
        this.$refs.sidebar.onBackdropClick = () => {
          this.sidebarVisible = false
        }
        
      }
      if(this.$refs.modal) {
        this.$refs.modal.onBackdropClick = () => {
          this.modalVisible = false
        }
      }
      this.$watch(
        () => this.$refs.rules?.flags?.dirty,
        val => {
          this.dirty = val
        },
      )
    }, 500)
  },
}
