<template>
  <validation-observer ref="rules" tag="form" @submit.prevent="submit">
    <div v-if="$can($acl.action.Create, $acl.subjects.Certificates)">
      <div class="selectBox">
        <v-select
          id="processId"
          v-model="selectedProcessId"
          :placeholder="
            $t('Global.Select', {
              subject: $t('Management.Processes.Process'),
            })
          "
          :options="paginated" :filterable="false" @search="onSearch"
          :label="'processId'"
          :key="'id'"
          :disabled="!$can($acl.action.Update, $acl.subjects.Processes)"
          @option:selected="processSelected"
        >
        <div slot="no-options">{{ $t("Global.NothingFound") }}</div>
          <template #option="{ processId }">
            <div class="d-flex">
              {{ processId }}
            </div>
          </template>
          <template #selected-option="{ processId }">
            <div>{{ processId }}</div>
          </template>
          <li slot="list-footer" class="pagination">
            <b-button
          v-if="$can($acl.action.Update, $acl.subjects.Processes)"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          :disabled="!hasPrevPage" @click="paginatePrev"
        >
        Vorherige
        </b-button>
        <b-button
          v-if="$can($acl.action.Update, $acl.subjects.Processes)"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          :disabled="!hasNextPage" @click="paginateNext"
        >
        Nächste
        </b-button>
          </li>
        </v-select>
      </div>
      <div id="print">
      <div id="printHandout">
        <PrintHandout
        :process="process"
        ></PrintHandout>
      </div>
      <div v-if="process.processType === 'VA'" id="printSeal">
        <PrintSeal
        :showText="true"
        iconSize="24"
        :process="process"
        ></PrintSeal>
      </div>
    </div>
    </div>

    <hr v-if="$can($acl.action.Create, $acl.subjects.Certificates)"/>
    <!-- row 1 -->
    <b-row>
      <b-col lg="3" md="3" sm="12">
        <b-form-group
          :label="
            process.processType === 'VA'
              ? $t('Management.Processes.ProcessId_VA')
              : $t('Management.Processes.ProcessId_Clearing')
          "
          label-for="processId"
        >
          <validation-provider
            #default="{ errors }"
            :name="
              process.processType === 'VA'
                ? $t('Management.Processes.ProcessId_VA')
                : $t('Management.Processes.ProcessId_Clearing')
            "
            rules="required"
          >
            <b-form-input
              id="processId"
              v-model="process.processId"
              :disabled="!$can($acl.action.Update, $acl.subjects.Processes)"
              :placeholder="
                $t('Global.Write', {
                  subject:
                    process.processType === 'VA'
                      ? $t('Management.Processes.ProcessId_VA')
                      : $t('Management.Processes.ProcessId_Clearing'),
                })
              "
              :state="errors.length > 0 ? false : null"
              autocomplete="off"
              @input="processIdIsUnique"
            />
            <small class="text-danger">{{ errors[0] }}</small>
            <small class="text-danger" v-if="!isUnique">{{ $t("Management.Processes.IsNotUnique") }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col lg="3" md="3" sm="12">
        <b-form-group
          :label="$t('Management.SealDistricts.SealDistrict')"
          label-for="sealDistrict"
        >
            <v-select
              id="sealDistrict"
              v-model="process.sealDistrict"
              :placeholder="
                $t('Global.Select', {
                  subject: $t('Management.SealDistricts.SealDistrict'),
                })
              "
              :options="sealDistricts"
              :label="'name'"
              :disabled="!$can($acl.action.Update, $acl.subjects.Processes)"
              @option:selected="sealDistrictSelected"
            >
            <div slot="no-options">{{ $t("Global.NothingFound") }}</div>
              <template #option="{ name }">
                <div class="d-flex">
                  {{ name }}
                </div>
              </template>
              <template #selected-option="{ name }">
                <div>{{ name }}</div>
              </template>
            </v-select>
        </b-form-group>
      </b-col>
      <b-col lg="3" md="3" sm="12">
        <b-form-group
          :label="$t('Management.Processes.Shareholder')"
          label-for="shareholder"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('Management.Processes.Shareholder')"
            rules="required"
          >
            <v-select
              id="shareholder"
              v-model="process.shareholder"
              :disabled="!$can($acl.action.Update, $acl.subjects.Processes)"
              :clearable="false"
              :placeholder="
                $t('Global.Select', {
                  subject: $t('Management.Processes.Shareholder'),
                })
              "
              :class="{
                invalid: errors.length > 0,
              }"
              :options="Shareholder"
            >
            <div slot="no-options">{{ $t("Global.NothingFound") }}</div>
              <template #option="{ label }">
                <div>
                  {{ $t(`Management.Processes.Shareholders.${label}`) }}
                </div>
              </template>
              <template #selected-option="{ label }">
                <div>
                  {{ $t(`Management.Processes.Shareholders.${label}`) }}
                </div>
              </template>
            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col lg="3" md="3" sm="12">
        <b-form-group
          :label="$t('Management.Processes.FillingQuantity')"
          label-for="fillingQuantity"
        >
          <CurrencyInput
            id="fillingQuantity"
            v-model="process.fillingQuantity"
            :options="{ currency: 'EUR', currencyDisplay: 'hidden', precision: 3, locale: 'de-DE' }"
            :disabled="!$can($acl.action.Update, $acl.subjects.Processes)"
            @input="checkFillingQuantity"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <!-- row 2 -->
    <b-row>
      <b-col lg="3" md="3" sm="12">
        <b-form-group
          :label="$t('Management.Processes.HarvestYear')"
          label-for="harvestYear"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('Management.Processes.HarvestYear')"
            rules="required"
          >
            <v-select
              id="harvestYear"
              v-model="process.harvestYear"
              :disabled="!$can($acl.action.Update, $acl.subjects.Processes)"
              :clearable="false"
              :placeholder="
                $t('Global.Select', {
                  subject: $t('Management.Processes.HarvestYear'),
                })
              "
              :class="{
                invalid: errors.length > 0,
              }"
              :options="harvestYears"
            >
            <div slot="no-options">{{ $t("Global.NothingFound") }}</div>
              <template #option="{ label }">
                <div>
                  {{ label }}
                </div>
              </template>
              <template #selected-option="{ label }">
                <div>
                  {{ label }}
                </div>
              </template>
            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col lg="3" md="3" sm="12">
        <b-form-group :label="$t('Management.Areas.Area')" label-for="areas">
          <validation-provider
            #default="{ errors }"
            :name="$t('Management.Areas.Area')"
            rules="required"
          >
            <v-select
              id="areas"
              multiple
              v-model="process.areas"
              :placeholder="
                $t('Global.Select', {
                  subject: $t('Management.Areas.Area'),
                })
              "
              :class="{
                invalid: errors.length > 0,
              }"
              :options="areas"
              :label="'label'"
              :disabled="!$can($acl.action.Update, $acl.subjects.Processes)"
              @option:selected="areaSelected"
              @option:deselected="areaDeselected"
            >
            <div slot="no-options">{{ $t("Global.NothingFound") }}</div>
              <template #option="{ label }">
                <div class="d-flex">
                  {{ label }}
                </div>
              </template>
              <template #selected-option="{ label }">
                <div>{{ label }}</div>
              </template>
            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col lg="3" md="3" sm="12">
        <b-form-group :label="$t('Management.Processes.Gga')" label-for="gga">
         
            <v-select
              id="gga"
              v-model="process.gga"
              :placeholder="
                $t('Global.Select', {
                  subject: $t('Management.Processes.Gga'),
                })
              "
              :options="ggas"
              :label="'name'"
              :disabled="!$can($acl.action.Update, $acl.subjects.Processes)"
            >
            <div slot="no-options">{{ $t("Global.NothingFound") }}</div>
              <template #option="{ name }">
                <div class="d-flex">
                  {{ name }}
                </div>
              </template>
              <template #selected-option="{ name }">
                <div>{{ name }}</div>
              </template>
            </v-select>
        </b-form-group>
      </b-col>
      <b-col lg="3" md="3" sm="12">
        <b-form-group
          :label="$t('Management.Processes.Stock')"
          label-for="stock"
        >
            <CurrencyInput
            id="stock"
            v-model="process.stock"
            :options="{ currency: 'EUR', currencyDisplay: 'hidden', precision: 3, locale: 'de-DE' }"
            :disabled="!$can($acl.action.Update, $acl.subjects.Processes)"
            @input="test"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col  lg="6" md="6" sm="12">  
        <b-form-group
          :label="$t('Management.Processes.HarvestYearCollection')"
          label-for="harvestYearCollection"
        >
            <b-form-input
              id="harvestYearCollection"
              v-model="process.harvestYearCollection"
              :disabled="!$can($acl.action.Update, $acl.subjects.Processes)"
              :placeholder="
                $t('Global.Write', {
                  subject:$t('Management.Processes.HarvestYearCollection'),})
              "
              autocomplete="off"
            />
          </b-form-group>
        </b-col>
    </b-row>

    <b-row>
      <b-col lg="6" md="6" sm="12">
        <b-row>
          <b-col lg="12" md="12" sm="12">
            <b-form-group :label="$t('Management.Processes.Hint')" label-for="hint">
              <b-form-textarea
                id="hint"
                no-resize
                rows="4"
                v-model="process.hint"
                :disabled="!$can($acl.action.Update, $acl.subjects.Processes)"
                :placeholder="
                  $t('Global.Write', {
                    subject: $t('Management.Processes.Hint'),
                  })
                "
                autocomplete="off"
              />
            </b-form-group>
          </b-col>
        </b-row>
        </b-col>
          <b-col lg="6" md="6" sm="12" >
            <b-row v-if="process.processType === 'VA'">
              <b-col lg="6" md="6" sm="12" >
                <b-form-group
                  :label="$t('Management.Processes.SortType')"
                  label-for="sortType"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('Management.Processes.SortType')"
                    rules="required"
                  >
                    <v-select
                      id="sortType"
                      v-model="process.sortType"
                      :disabled="!$can($acl.action.Update, $acl.subjects.Processes)"
                      :clearable="false"
                      :placeholder="
                        $t('Global.Select', {
                          subject: $t('Management.Processes.SortType'),
                        })
                      "
                      :class="{
                        invalid: errors.length > 0,
                      }"
                      :options="SortTypes"
                    >
                    <div slot="no-options">{{ $t("Global.NothingFound") }}</div>
                      <template #option="{ label }">
                        <div>
                          {{ $t(`Management.Processes.SortTypes.${label}`) }}
                        </div>
                      </template>
                      <template #selected-option="{ label }">
                        <div>
                          {{ $t(`Management.Processes.SortTypes.${label}`) }}
                        </div>
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="6" md="6" sm="12">
                <b-form-group
                  :label="$t('Management.Processes.Unit')"
                  label-for="unit"
                >
                    <v-select
                      id="unit"
                      v-model="process.unit"
                      :disabled="!$can($acl.action.Update, $acl.subjects.Processes)"
                      :clearable="false"
                      :placeholder="
                        $t('Global.Select', {
                          subject: $t('Management.Processes.Unit'),
                        })
                      "
                      :options="units"
                    >
                    <div slot="no-options">{{ $t("Global.NothingFound") }}</div>
                      <template #option="{ label }">
                        <div>
                          {{ label }}
                        </div>
                      </template>
                      <template #selected-option="{ label }">
                        <div>
                          {{ label }}
                        </div>
                      </template>
                    </v-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="6" md="6" sm="12">
                <b-form-group
                  :label="$t('Management.Processes.RawHop')"
                  label-for="rawHop"
                >
                  <CurrencyInput
                    id="rawHop"
                    v-model="process.rawHop"
                    :options="{ currency: 'EUR', currencyDisplay: 'hidden', precision: 3, locale: 'de-DE' }"
                    :disabled="!$can($acl.action.Update, $acl.subjects.Processes)"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="6" md="6" sm="12" class="c-check">
                <b-form-checkbox
                  id="showText"
                  v-model="process.showText"
                  :value="true"
                  :unchecked-value="false"
                  :disabled="!$can($acl.action.Update, $acl.subjects.Processes)"
                >
                  {{ $t('Management.Processes.ShowText') }}
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="6" md="6" sm="12">
              
              </b-col>
              <b-col lg="6" md="6" sm="12">
                <b-form-checkbox
                  id="calculateStock"
                  v-model="process.calculateStock"
                  :value="true"
                  :unchecked-value="false"
                  :disabled="!$can($acl.action.Update, $acl.subjects.Processes)"
                >
                  {{ $t('Management.Processes.CalculateStock') }}
                </b-form-checkbox>
              </b-col>
            </b-row>
        </b-col>
      </b-row>

    <b-row>
      <b-col lg="12" md="12" sm="12">
        <b-row class="sort-container">
          <b-col>
            <h5>{{ $t("Management.Sorts.Sorts") }}</h5>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <div>
      <b-row v-for="(sort, index) in process.sorts" :key="index">
        <b-col lg="6" md="6" sm="8">
          <b-form-group :label="$t('Management.Sorts.Sort')" label-for="sort">
            <validation-provider
              #default="{ errors }"
              :name="$t('Management.Sorts.Sort')"
              rules="required"
            >
              <v-select
                id="sort"
                v-model="sort.label"
                :placeholder="
                  $t('Global.Select', {
                    subject: $t('Management.Sorts.Sort'),
                  })
                "
                :class="{
                  invalid: errors.length > 0,
                }"
                :options="sorts"
                :label="'label'"
                :disabled="!$can($acl.action.Update, $acl.subjects.Processes)"
                @option:selected="sortSelected($event, index)"
              >
              <div slot="no-options">{{ $t("Global.NothingFound") }}</div>
                <template v-slot:option="option">
                  <div :class="!option.active ? 'inactive-background flex' : ''">
                    {{ option.label }}

                    <feather-icon
                      v-if="option.active"
                      icon="CheckCircleIcon"
                      size="16"
                    />
                    <feather-icon
                      v-else
                      icon="XCircleIcon"
                      size="16"
                    />
                  </div>

                </template>
                <template #selected-option="{ label }">
                  <div>{{ label }}</div>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col lg="5" md="5" sm="5">
          <b-form-group
            :label="$t('Management.Processes.SortPercentage')"
            label-for="sort"
          >
            <validation-provider
              #default="{ errors }"
              :name="`${count}`"
              :rules="`percentage: ${count}`"
            >
              <div class="text-center">
                <b-form-input
                  id="sortPercentage"
                  v-model="sort.sortPercentage"
                  :disabled="!$can($acl.action.Update, $acl.subjects.Processes)"
                  :placeholder="
                    $t('Global.Write', {
                      subject: $t('Management.Processes.SortPercentage'),
                    })
                  "
                  :state="errors.length === 0"
                  autocomplete="off"
                  :type="'number'"
                  step="0.01"
                  min="0"
                  max="100"
                  @update="percentageValid"
                />
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="1"
          md="1"
          sm="2"
          class="text-center align-self-center d-flex"
        >
          <div v-if="process.sorts.length > 1 && $can($acl.action.Update, $acl.subjects.Processes)">
            <feather-icon
              icon="TrashIcon"
              size="25"
              class="hover-red"
              @click="removeSort(sort)"
            />
          </div>
          <div v-if="index === process.sorts.length - 1 && $can($acl.action.Update, $acl.subjects.Processes)">
            <feather-icon
              icon="PlusCircleIcon"
              size="25"
              class="hover-primary"
              @click="addSort"
            />
          </div>
        </b-col>
      </b-row>
    </div>

    <b-row>
      <b-col lg="4" md="4" sm="12">
        <b-button
          v-if="$can($acl.action.Update, $acl.subjects.Processes)"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          type="submit"
          :disabled="!isUnique"
        >
          {{ $t("Global.Save") }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          :class="{ 'ml-3': $can($acl.action.Update, $acl.subjects.Processes) }"
          @click="$router.push({ name: 'seals/processes' })"
        >
          {{ $t("Global.Retreat") }}
        </b-button>
      </b-col>
    </b-row>

    <AreaModal
      ref="areaModal"
      v-if="$can($acl.action.Create, $acl.subjects.Areas)"
      :can="$can($acl.action.Create, $acl.subjects.Areas)"
      :countries="countries"
      @submitArea="addArea"
      @resetArea="resetArea"
    />

    <SealDistrictModal
      ref="sealDistrictModal"
      v-if="$can($acl.action.Create, $acl.subjects.SealDistricts)"
      :can="$can($acl.action.Create, $acl.subjects.SealDistricts)"
      @submitSealDistrict="addSealDistrict"
      @resetSealDistrict="resetSealDistrict"
    />

    <SortModal
      ref="sortModal"
      v-if="$can($acl.action.Create, $acl.subjects.Sorts)"
      :can="$can($acl.action.Create, $acl.subjects.Sorts)"
      @submitSort="sortSubmitted"
      @resetSort="resetSort"
    />

    <b-modal
          id="raw-warning-modal"
          centered
          no-close-on-backdrop
          no-close-on-esc
          no-enforce-focus
          class="modal-dialog modal-dialog-centered"
          ref="warningModal"
        >
          <template #modal-title>
            {{ $t("Global.Warning") }}
          </template>
          <template>
           {{ $t("Management.Processes.RawWarning") }}
          </template>
          <template #modal-footer>
            <div class="buttons-container d-flex">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="$bvModal.hide('raw-warning-modal')"
              >
                {{ $t("Global.OK") }}
              </b-button>
            </div>
          </template>
        </b-modal>
  </validation-observer>
</template>

<script>
import {
  BFormInput,
  BFormTextarea,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormCheckbox,
  BModal,
} from "bootstrap-vue";

import vSelect from "vue-select";
import {
  Shareholder,
  SortGroups,
  SortTypes,
  PrintTitlesEnum,
  ProcessTypesEnum,
  SortTypesEnum,
} from "@/data/enums";
import { preventUnsavedChangesRouteLevel } from "@/utility/mixins/preventUnsavedChanges";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import AreaModal from "../../areas/area-modal.vue";
import SealDistrictModal from "../../sealdistricts/seal-district-modal.vue";
import SortModal from"../../sorts/sort-modal.vue";
import PrintSeal from "../../certs-and-docs/seal/printSeal.vue";
import PrintHandout from "../../certs-and-docs/handout/printHandout.vue";
import CurrencyInput from "@/views/miscellaneous/CurrencyInput.vue";

export default {
  components: {
    vSelect,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    ValidationObserver,
    ValidationProvider,
    AreaModal,
    SealDistrictModal,
    SortModal,
    PrintSeal,
    PrintHandout,
    CurrencyInput,
    BFormCheckbox,
    BModal
  },
  mixins: [preventUnsavedChangesRouteLevel],

  props: {
    process: {
      type: Object,
      default: () => null,
    },
  },



  computed: {
    filtered() {
      return this.processes.filter((process) =>
        process?.processId.toLowerCase().includes(this.search.toLowerCase()) && process.processType === this.process.processType
      )
    },
    paginated() {
      return this.filtered.slice(this.offset, this.limit + this.offset)
    },
    hasNextPage() {
      const nextOffset = this.offset + this.limit
      return Boolean(
        this.filtered.slice(nextOffset, this.limit + nextOffset).length
      )
    },
    hasPrevPage() {
      const prevOffset = this.offset - this.limit
      return Boolean(
        this.filtered.slice(prevOffset, this.limit + prevOffset).length
      )
    },
  },

  data: () => ({
    countries: [],
    selectedCountries: [],
    selectedSortGroups: [],
    sealDistricts: [],
    sorts: [],
    sortsCopy: [],
    areas: [],
    ggas: [],
    emptyArea: [],
    harvestYears: [],
    Shareholder,
    SortGroups,
    SortTypes,
    required,
    label: "name",
    sortPercentageError: "",
    count: 0,
    processes: [],
    SortTypesEnum,
    isUnique: true,
    units:["", "1", "2", "3", "4", "5"],
    search: '',
    offset: 0,
    limit: 10,
    selectedProcessId: 0,
  }),

  async created() {
    this.load();
  },

  methods: {
    async load() {
      this.loadProcesses();
    this.getGga();
    this.getCountries();
    this.getSorts();
    this.getAreas();
    this.getSealDistricts();
    this.populateHarvestYears();


    if (this.process !== null) {
      if (this.process.sorts.length === 0) {
        this.process.sorts.push({
          id: 0,
          name: "",
          sortPercentage: 0,
        });
      }

      this.process.areas.forEach((area) => {
        this.selectedCountries.push(area.country);
      });
      this.process.sorts.forEach((sort) => {
        this.count += Number(sort.sortPercentage);
        this.selectedSortGroups.push(sort.group);
      });
      this.process.processType = this.process.processType
        ? this.process.processType
        : this.$route.params.selectedProcessType;
    }
    },
    async loadProcesses() {
        try{
            if(this.processes.length === 0){
                const { data } = await this.$axios.get(`processes/byHarvestYear/${this.process.harvestYear}`);
                this.processes = data;
            }

        } catch (error) {
            this.$alert.error();
        }
    },
    async submit() {
      this.percentageValid();
      const valid = await this.$refs.rules.validate();
      this.process.printTitle = PrintTitlesEnum.DH_L;
      if(this.process.gga === null){
        this.process.gga = this.emptyArea[0];
      }
      if (this.process.processType === ProcessTypesEnum.Leerung) {
        this.process.sortType = SortTypesEnum.Leerung;
      }
      if (valid) {
        this.$emit("submit");
        return;
      }
      this.$alert.invalid();
    },
    test(val){
      if(val)
        this.process.calc = false;
    },

    async getCountries() {
      try {
        const { data } = await this.$axios.get(`countries`);
        this.countries = data;
      } catch (error) {
        this.$alert.error();
        this.$router.push({ name: "countries" });
      }
    },

    async getSealDistricts() {
      try {
        const { data } = await this.$axios.get(`sealdistricts`);
        this.sealDistricts = data;
        const newSealDistrictOption = [
          {
            id: "0",
            name: this.$tc("Global.New_2") + " " + this.$t(`Global.${"Create"}`, { subject: this.$t("Management.SealDistricts.SealDistrict"), }),
            short: "neu",
          },
        ];
        this.sealDistricts = [].concat(newSealDistrictOption, this.sealDistricts);
      } catch (error) {
        this.$alert.error();
        this.$router.push({ name: "sealdistricts" });
      }
    },

    async getAreas() {
      try {
        const { data } = await this.$axios.get(`areas`);
        this.areas = data;

        const newAreaOption = [
          {
            id: "0",
            name:
              this.$tc("Global.New", 4) +
              " " +
              this.$t(`Global.${"Create"}`, {
                subject: this.$t("Management.Areas.Area"),
              }),
            short: "neu",
          },
        ];
        this.areas = [].concat(newAreaOption, this.areas);

        this.areas.map(function (x) {
          return (x.label =
            x.id === "0"
              ? x.name
              : x.name + " - " + x.short + " (" + x.country?.short + ")");
        });
        this.ggas = this.areas.filter((area) => area.country?.short === "DE" && area.name !== "Deutschland" && area.short !== "DER");
        this.ggas.push(this.emptyArea[0]);
      } catch (error) {
        this.$alert.error();
        this.$router.push({ name: "areas" });
      }
    },

    async getGga() {
      try {
      const { data } = await this.$axios.get(`areas/default/getEmptyArea`);
      this.emptyArea = data;
      this.ggas = JSON.parse(JSON.stringify(this.emptyArea));
      if(this.process.gga === null){
        this.process.gga = this.emptyArea;
      }
    } catch (error) {
        this.$alert.error();
        this.$router.push({ name: "areas" });
      }
    },

    async getSorts() {
      try {
        const { data } = await this.$axios.get(`sorts`);
        this.sorts = data;

        const newSortOption = [
          {
            id: "0",
            name: this.$tc("Global.New", 1) + " " + this.$t(`Global.${"Create"}`, { subject: this.$t("Management.Sorts.Sort"), }),
            short: "neu",
            active: true,
          },
        ];
        this.sorts = [].concat(newSortOption, this.sorts);
        this.sortsCopy = JSON.parse(JSON.stringify(this.sorts));
        this.mapSorts();
      } catch (error) {
        this.$alert.error();
        this.$router.push({ name: "sorts" });
      }
    },

    populateHarvestYears() {
      const dateNow = new Date(Date.now());
      let year = dateNow.getFullYear();
      if( dateNow.getMonth() < 8 ){
        year--;
      }
      for (
        year;
        year >= 2006;
        year--
      ) {
        this.harvestYears.push(year.toString());
      }
    },

    areaSelected() {
      const index = this.process.areas.findIndex((x) => x.id === "0");
      if (index > -1) {
        this.$refs.areaModal.modalVisible = true;
        return;
      }
      this.areaDeselected();
    },

    areaDeselected() {
      if (
        this.process.areas.findIndex((area) => area.country?.short === "DE") ===
        -1
      ) {
        this.ggas = JSON.parse(JSON.stringify(this.emptyArea));
        this.process.gga = this.emptyArea[0];
      } else {
        this.ggas = this.areas.filter((area) => area.country?.short === "DE" && area.name !== "Deutschland" && area.short !== "DER");
        this.ggas.push(this.emptyArea[0]);
        this.process.gga = this.emptyArea[0];
      }
      if (this.process.areas.length === 0) {
        this.process.gga = this.emptyArea[0];
      }
    },

    addArea(newArea) {
      this.areas.push(newArea);
      this.areas.map(function (x) {
        return (x.label =
          x.id === "0"
            ? x.name
            : x.name + " - " + x.short + " (" + x.country?.short + ")");
      });
      this.process.areas.splice(this.process.areas.findIndex((x) => x.id === "0"), 1);
      this.process.areas.push(newArea);
      this.$refs.areaModal.modalVisible = false;
    },

    resetArea() {
      const index = this.process.areas.findIndex((x) => x.id === "0");
      this.process.areas.splice(index, 1);
      if (this.$refs.areaModal.modalVisible) {
        this.$refs.areaModal.modalVisible = false;
      }
    },

    sealDistrictSelected(optionSelected) {
      if (optionSelected.id === "0") {
        this.$refs.sealDistrictModal.modalVisible = true;
        this.process.sealDistrict = "";
        return;
      }
    },

    addSealDistrict(newSealDistrict) {
      this.sealDistricts.push(newSealDistrict);
      this.process.sealDistrict = newSealDistrict;
      this.$refs.sealDistrictModal.modalVisible = false;
    },

    resetSealDistrict() {
      if (this.$refs.sealDistrictModal.modalVisible) {
        this.$refs.sealDistrictModal.modalVisible = false;
      }
    },

    mapSorts(){
      this.sorts.map(function (x) {
          return (x.label = x.id === "0" ? x.name : x.name + " - " + x.short + " (" + x.group + ")");
        });
    },

    sortSubmitted(newSort) {
      this.sorts.push(newSort);
      this.$refs.sortModal.modalVisible = false;
      this.mapSorts();
    },

    resetSort() {
      if (this.$refs.sortModal.modalVisible) {
        this.$refs.sortModal.modalVisible = false;
      }
      const index = this.process.sorts.findIndex((x) => x.id === "0");
      if (index > -1) {
        this.process.sorts.splice(index, 1);
        this.addSort();
      }
    },

    sortSelected(newSort, alteredIndex) {
      if (newSort.id === "0") {
        this.$refs.sortModal.modalVisible = true;
        return;
      }

      const index = this.process.sorts.findIndex((sort) => sort.id === "0");
      if(index > -1){
        newSort.sortPercentage = this.process.sorts.length === 0 ? 100 : 0;
        this.process.sorts.splice(index, 1);
        this.process.sorts.push(newSort);
      } else{
        const percentage = this.process.sorts[alteredIndex].sortPercentage;
        this.process.sorts[alteredIndex] = newSort;
        this.process.sorts[alteredIndex].sortPercentage = percentage;
      }


      this.sorts = JSON.parse(JSON.stringify(this.sortsCopy));
      this.mapSorts();
      this.percentageValid();
    },

    addSort() {
      this.process.sorts.push({
        id: "0",
        name: "",
        sortPercentage: 0,
      });
      this.mapSorts();
      this.percentageValid();
    },

    removeSort(sortToRemove) {
      this.process.sorts.splice(
        this.process.sorts.findIndex((sort) => sort.id === sortToRemove.id),
        1
      );
      if (this.process.sorts.length === 0) {
        this.process.sorts.push({
          id: 0,
          name: "",
          sortPercentage: 0,
        });
      }
      this.percentageValid();
    },

    async processIdIsUnique(){
      if(this.process.processId){
        try {
          const { data } = await this.$axios.get(
            "processes/findByProcessId/" + this.process.processId
          );
          if(data){
            this.isUnique = false;
          } else{
            this.isUnique = true;
          }

        } catch {}
      }

    },

    checkFillingQuantity(value){
      if(this.process.processType === ProcessTypesEnum.VA && this.process.fillingQuantity > this.process.rawHop){
        this.$bvModal.show('raw-warning-modal');
      }
    },

    percentageValid() {
      this.count = 0;
      this.process.sorts.forEach((sort) => {
        this.count += Number(sort.sortPercentage);
      });
      this.count = parseFloat(this.count.toFixed(2))
      this.sortPercentageError =
        this.count === 100
          ? ""
          : this.$t("Management.Processes.SortPercentage_100");
    },

    weightFormatter(value) {
      return Intl.NumberFormat("de-DE").format(value)
    },
    processSelected(process) {
        this.$emit('changeProcess', process.id)
    },
    onSearch(query) {
      this.search = query
      this.offset = 0
    },
    paginateNext(){
      this.offset += this.limit;
    },
    paginatePrev(){
      this.offset -= this.limit;
    }
  },
};
</script>
<style lang="scss">
.sort-container {
  border-top: 1px solid #dbdbdb;
  margin-top: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
}
#print{
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
  margin-top: -1.5rem;
  justify-content: end;
}
.c-check{
  margin-top: 1.5rem;
}
.selectBox {
  width: 30%;
}

.inactive-background {
  background: rgb(245, 245, 245);
  color: #cacaca;
}
</style>
