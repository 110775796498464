<template>
  <b-card v-if="process" class="p-2">
    <Form
    ref="form"
    :process="process"
    :can="$can($acl.action.Create, $acl.subjects.Processes)"
    @submit="submit"
    ></Form>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue';
import Form from './components/form.vue';

export default {

    components: {
      BCard,
      Form
    },

    data: () => ({
        process: {
          processId: null,
          processType: null,
          sealDistrict: null,
          fillingQuantity: null,
          harvestYear: new Date(Date.now()).getMonth() < 8 ? (new Date(Date.now()).getFullYear() - 1).toString() : new Date(Date.now()).getFullYear().toString(),
          areas: [],
          sorts: [],
          stock: null,
          shareholder: null,
          gga: null,
          sortType: null,
          customer: null,
          hint: null,
          unit: "",
          rawHop: null,
          showText: false,
          calculateStock: true,
        },
    }),
    methods:{
      reset(){
        this.process = {
          processId: null,
          sealDistrict: null,
          fillingQuantity: null,
          harvestYear: new Date(Date.now()).getMonth() < 9 ? (new Date(Date.now()).getFullYear() - 1).toString() : new Date(Date.now()).getFullYear().toString(),
          harvestYearCollection: null,
          areas: [],
          sorts: [],
          stock: null,
          shareholder: null,
          gga: null,
          sortType: null,
          sortGroup: null,
          customer: null,
          hint: null,
          unit: "",
          rawHop: null,
          showText: false,
          calculateStock: true,
        }
      },
      async submit() {
        try {
          
          const newProcess = JSON.parse(JSON.stringify(this.process));
          let areas = [];
          newProcess.areas.forEach(area => {
            areas.push(area.id)
          })
          newProcess.areas = areas;

          newProcess.sealDistrict = newProcess.sealDistrict?.id;
          newProcess.customer = newProcess.customer?.id;
          newProcess.gga = newProcess.gga?.id;
          newProcess.fillingQuantity = Number(newProcess.fillingQuantity);
          newProcess.stock = Number(newProcess.stock);
          newProcess.unit = newProcess.unit;
          newProcess.rawHop = Number(newProcess.rawHop)

          const { data } = await this.$axios.post('processes', newProcess);
          this.$alert.create(`${data.processId}`)
          this.$emit('add', data)
          const id = data.id;
          this.$router.push({
            name: 'seals/processes-update',
            params: { id },
          })
          const { form } = this.$refs
          form.dirty = false
          // form.sidebarVisible = false
        } catch {
          this.$alert.error()
        }
    },
    },
}
</script>
