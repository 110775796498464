<template>
    <b-modal ref="modal" centered no-close-on-backdrop no-close-on-esc no-enforce-focus
        class="modal-dialog modal-dialog-centered">
        <template #modal-title>
            {{ $tc("Global.New", 2) }} {{ $t("Management.Sorts.Sort") }}
        </template>
        <validation-observer ref="rules" tag="form" class="p-2" @submit.prevent="submit">
            <section v-if="sort">
                <b-form-group :label="$t('Management.Sorts.Sort')" label-for="sort">
                    <validation-provider #default="{ errors }" :name="$t('Management.Sorts.Sort')" rules="required">
                        <b-form-input id="sort" v-model="sort.name" :placeholder="
                            $t('Global.Write', {
                                subject: $t('Management.Sorts.Sort'),
                            })
                        " :state="errors.length > 0 ? false : null" :disabled="!can" autocomplete="off" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

                <b-form-group :label="$t('Management.Sorts.Short')" label-for="short">
                    <validation-provider #default="{ errors }" :name="$t('Management.Sorts.Short')" rules="required">
                        <b-form-input id="short" v-model="sort.short" :placeholder="
                            $t('Global.Write', {
                                subject: $t('Management.Sorts.Short'),
                            })
                        " :state="errors.length > 0 ? false : null" :disabled="!can" autocomplete="off" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

                <b-form-group :label="$t('Management.Sorts.Group')" label-for="group">
                    <validation-provider
                        #default="{ errors }"
                        :name="$t('Management.Sorts.Group')"
                        rules="required"
                    >

                        <v-select id="group" v-model="sort.group" :placeholder="
                            $t('Global.Select', {
                                subject: $t('Management.Sorts.Group'),
                            })" 
                            :class="{
                                invalid: errors.length > 0,
                            }"
                            :options="Groups" 
                            :disabled="!can" 
                            :label="'name'"
                            >
                            <div slot="no-options">{{ $t("Global.NothingFound") }}</div>
                            <template #option="{ name }">
                                <div class="d-flex">
                                    {{ name }}
                                </div>
                            </template>
                            <template #selected-option="{ name }">
                                <div>{{ name }}</div>
                            </template>

                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>


            </section>
        </validation-observer>
        <template #modal-footer>
            <div class="buttons-container d-flex">
                <b-button v-if="can" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="submit">
                    {{ $t("Global.Save") }}
                </b-button>

                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-secondary"
                    @click="modalVisible = false">
                    {{ $t("Global.Retreat") }}
                </b-button>
            </div>
        </template>
    </b-modal>
</template>
  
<script>
import { BButton, BFormInput, BFormGroup } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { preventUnsavedChangesPopupLevel } from "@/utility/mixins/preventUnsavedChanges";
import { required } from "@validations";
import vSelect from "vue-select";
import { Groups } from '@/data/enums';

export default {
    components: {
        vSelect,
        ValidationProvider,
        ValidationObserver,
        BButton,
        BFormInput,
        BFormGroup,
    },

    mixins: [preventUnsavedChangesPopupLevel],

    props: {
        can: {
            type: Boolean,
            required: true,
        },
    },

    data: () => ({
        required,
        Groups,
        sort: {
            name: null,
            short: null,
            group: null,
        },
    }),

    methods: {
        async reset() {
            this.sort = {
                name: null,
                short: null,
                group: null,
            }
            this.$refs.rules.reset();
            this.$emit("resetSort");
        },
        async submit() {
            const valid = await this.$refs.rules.validate();

            if (valid) {
                const { data } = await this.$axios.post('sorts', this.sort);
                this.sort = data;
                this.$alert.create(`${data.name}`);

                this.$emit("submitSort", data);
                return;
            }
            this.$alert.invalid();
        },
    },
};
</script>
<style lang="scss">
.buttons-container {
    gap: 1rem;
}
</style>
  