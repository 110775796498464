<template>
    <b-modal
      ref="modal"
      centered
      no-close-on-backdrop
      no-close-on-esc
      no-enforce-focus
      class="modal-dialog modal-dialog-centered"
    >
      <template #modal-title>
        {{ $tc("Global.New", 0) }} {{ $t("Management.SealDistricts.SealDistrict") }}
      </template>
      <validation-observer
        ref="rules"
        tag="form"
        class="p-2"
        @submit.prevent="submit"
      >
        <section v-if="sealDistrict">
          <b-form-group :label="$t('Management.SealDistricts.SealDistrict')" label-for="sealDistrict" >
            <validation-provider
              #default="{ errors }"
              :name="$t('Management.SealDistricts.SealDistrict')"
              rules="required"
            >
              <b-form-input 
                id="sealDistrict"
                v-model="sealDistrict.name"
                :placeholder="
                  $t('Global.Write', {
                    subject: $t('Management.SealDistricts.SealDistrict'),
                  })
                "
                :state="errors.length > 0 ? false : null"
                :disabled="!can"
                autocomplete="off"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </section>
      </validation-observer>
      <template #modal-footer>
        <div class="buttons-container d-flex">
          <b-button
            v-if="can"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="submit"
          >
            {{ $t("Global.Save") }}
          </b-button>
  
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="modalVisible = false"
          >
            {{ $t("Global.Retreat") }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </template>
  
  <script>
  import { BButton, BFormInput, BFormGroup } from "bootstrap-vue";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { preventUnsavedChangesPopupLevel } from "@/utility/mixins/preventUnsavedChanges";
  import { required } from "@validations";
  
  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      BButton,
      BFormInput,
      BFormGroup,
    },
  
    mixins: [preventUnsavedChangesPopupLevel],
  
    props: {
      can: {
        type: Boolean,
        required: true,
      },
    },
  
    data: () => ({
      required,
      sealDistrict: {
        name: null,
        short: null,
      },
    }),
  
    methods: {
      reset() {
        this.sealDistrict = {
          name: null,
          short: null,
        }
        this.$refs.rules.reset();
      },
      async submit() {
        const valid = await this.$refs.rules.validate();
  
        if (valid) {
          const { data } = await this.$axios.post('sealdistricts', this.sealDistrict);
          this.sealDistrict = data;
          this.$alert.create(`${data.name}`);
  
          this.$emit("submitSealDistrict", data);
          return;
        }
        this.$alert.invalid();
      },
    },
  };
  </script>
  <style lang="scss">
  .buttons-container {
    gap: 1rem;
  }
  </style>
  